import { changeI18nLanguage, I18NEXT, type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { useEffect, useState } from 'react';

import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../components';
import { type Icon, UnitedKingdomFlagIcon, VietnamFlagIcon } from '../../icons';
import { cn } from '../../utils';

const TRANSLATION: I18nTranslation = {
  en: {
    vi: 'Vietnamese',
    en: 'English',
    language: 'Language',
  },
  vi: {
    vi: 'Tiếng Việt',
    en: 'Tiếng Anh',
    language: 'Ngôn ngữ',
  },
};

type LanguageItem = {
  locale: string;
  Icon: Icon;
};

const LANGUAGE_ITEMS: LanguageItem[] = [
  {
    locale: 'vi',
    Icon: VietnamFlagIcon,
  },
  {
    locale: 'en',
    Icon: UnitedKingdomFlagIcon,
  },
];

type LanguageSwitcherProps = {
  className?: string;
};

const LanguageSwitcher = ({ className }: LanguageSwitcherProps) => {
  const {
    t,
    i18n: { language },
  } = useExtendI18nTranslation(TRANSLATION);
  const [selectedLang, setSelectedLang] = useState<LanguageItem>(
    LANGUAGE_ITEMS.find(({ locale }) => locale === language)!
  );

  useEffect(() => {
    const currentLang = LANGUAGE_ITEMS.find(({ locale }) => I18NEXT.language === locale);
    currentLang && setSelectedLang(currentLang);
  }, []);

  const handleChangeLanguage = (languageItem: LanguageItem) => {
    changeI18nLanguage(languageItem.locale);
    setSelectedLang(languageItem);
  };

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <p className="text-sm text-neutral-5">{t('language')}</p>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            <selectedLang.Icon className="mr-2 w-4 h-4" />
            <p className="uppercase">{selectedLang.locale}</p>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {LANGUAGE_ITEMS.map((languageItem) => (
            <DropdownMenuItem key={languageItem.locale} onClick={() => handleChangeLanguage(languageItem)}>
              <languageItem.Icon className="mr-2 w-4 h-4" />
              <p>{t(languageItem.locale)}</p>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default LanguageSwitcher;
