import I18NEXT from './i18next';

export type I18nTranslation = {
  en: Record<string, string>;
  vi: Record<string, string>;
};

const extendI18nTranslation = (...translations: I18nTranslation[]) => {
  const translation = mergeTranslation(...translations);
  Object.keys(translation).forEach((locale) => {
    I18NEXT.addResources(locale, 'translation', translation[locale as keyof I18nTranslation]);
  });
};

const changeI18nLanguage = (language: string) => {
  void I18NEXT.changeLanguage(language);
  localStorage.setItem('language', language);
};

const mergeTranslation = (...translations: I18nTranslation[]) =>
  translations.reduce<I18nTranslation>(
    (accumulator, currentValue) => ({
      en: {
        ...accumulator.en,
        ...currentValue.en,
      },
      vi: {
        ...accumulator.vi,
        ...currentValue.vi,
      },
    }),
    { en: {}, vi: {} }
  );

export { changeI18nLanguage, extendI18nTranslation };
