import { LoadingIndicator } from './base';

const LoadingScreen = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <LoadingIndicator className="text-primary-4" />
    </div>
  );
};

export default LoadingScreen;
