import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';

const MAX_RETRIES = 1;

const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: (failureCount, error) => {
        // Don't retry for certain error responses
        // https://github.com/TanStack/query/discussions/372#discussioncomment-6023276
        // 4xx client errors: This class of status code is intended for situations in which the error seems to have been caused by the client.
        if (
          axios.isAxiosError(error) &&
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          return false;
        }

        return failureCount <= MAX_RETRIES;
      },
    },
  },
});

export default QUERY_CLIENT;
