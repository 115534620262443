// CAUSE ENUM DEFINITION BASE ON BACKEND ERROR CAUSE
// BE CAREFUL WHEN CHANGING THE VALUE
export enum ErrorCauseEnum {
  ACCESS_TOKEN_EXPIRED = 'access-token',
  REFRESH_TOKEN_EXPIRED = 'refresh-token',
  REQUIRE_SYSTEM_ADMIN = 'admin',
}

export type ApiConfig = {
  baseURL: string;
  authentication?: AuthenticationConfig;
};

export type AuthenticationConfig = {
  redirectURL: string;
  subdomain: string;
};
