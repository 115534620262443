import type { ComponentProps } from 'react';

const BluemarkerFullLogo = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="174" height="31" viewBox="0 0 174 31" fill="none" {...props}>
      <path
        d="M0.634724 1.50418C0.641012 0.699959 1.29806 0.0531047 2.10228 0.0593926L11.0578 0.129412C14.6365 0.157393 17.515 3.08126 17.4871 6.66005C17.4591 10.2388 14.5352 13.1173 10.9564 13.0894L2.00095 13.0193C1.19673 13.0131 0.549878 12.356 0.556166 11.5518L0.634724 1.50418Z"
        fill="#0B378C"
      />
      <path
        d="M0.510625 17.3765C0.516913 16.5723 1.17396 15.9254 1.97818 15.9317L10.9337 16.0017C14.5125 16.0297 17.3909 18.9536 17.363 22.5323C17.335 26.1111 14.4111 28.9896 10.8323 28.9617L1.87686 28.8916C1.07263 28.8854 0.425779 28.2283 0.432067 27.4241L0.510625 17.3765Z"
        fill="#0B378C"
      />
      <path
        d="M22.6185 29.0538C21.9017 29.0482 21.3141 28.8772 20.8558 28.5407C20.3976 28.2043 20.17 27.8312 20.1733 27.4216L20.3744 1.69052C20.378 1.22967 20.6114 0.860224 21.0744 0.582193C21.5375 0.304163 22.1275 0.167951 22.8443 0.173556C23.5356 0.17896 24.1234 0.324381 24.6076 0.609818C25.0919 0.895256 25.3322 1.2684 25.3286 1.72926L25.1274 27.4603C25.1242 27.87 24.8781 28.2393 24.3891 28.5684C23.9 28.8974 23.3098 29.0592 22.6185 29.0538Z"
        fill="#0B378C"
      />
      <path
        d="M36.521 29.5082C34.9848 29.4962 33.5794 29.1267 32.3049 28.3998C31.0562 27.6475 30.0523 26.6795 29.2935 25.4957C28.5604 24.2866 28.1991 23.0035 28.2097 21.6466L28.2818 12.4295C28.2848 12.0454 28.5308 11.6889 29.0199 11.3599C29.5346 11.031 30.1247 10.8692 30.7904 10.8744C31.4561 10.8796 32.0309 11.0505 32.5147 11.3872C32.9986 11.7238 33.239 12.0842 33.236 12.4682L33.1639 21.6853C33.1593 22.2742 33.3087 22.8259 33.6119 23.3403C33.9151 23.8548 34.3214 24.2932 34.8306 24.6557C35.3401 24.9925 35.902 25.1633 36.5165 25.1682C37.1566 25.1732 37.7212 25.0111 38.2102 24.6821C38.7249 24.3533 39.1379 23.9212 39.4494 23.386C39.7608 22.8507 39.9187 22.3014 39.9231 21.7382L39.9955 12.4827C39.9987 12.073 40.2447 11.7165 40.7336 11.413C41.2481 11.1098 41.8253 10.9607 42.4654 10.9657C43.2079 10.9715 43.8084 11.1298 44.2668 11.4407C44.7253 11.7515 44.9529 12.1117 44.9497 12.5214L44.8773 21.7769C44.8665 23.1595 44.4724 24.4494 43.6949 25.6468C42.9432 26.8187 41.9245 27.7581 40.6387 28.465C39.3785 29.172 38.006 29.5198 36.521 29.5082Z"
        fill="#0B378C"
      />
      <path
        d="M56.1232 29.6614C54.3054 29.6472 52.6949 29.3146 51.2918 28.6635C49.9144 27.987 48.8335 27.0568 48.049 25.8728C47.2647 24.6633 46.8787 23.2776 46.8909 21.7158L46.9164 18.4514C46.9272 17.0689 47.3084 15.8044 48.0599 14.6581C48.8372 13.4864 49.8558 12.5597 51.1157 11.8783C52.4015 11.1714 53.8253 10.8241 55.3871 10.8363C56.8208 10.8475 58.1242 11.1649 59.2971 11.7886C60.4957 12.4125 61.462 13.265 62.1961 14.3461C62.9301 15.4273 63.2916 16.6847 63.2804 18.1185C63.2718 19.2194 63.0863 20.0117 62.7241 20.4954C62.362 20.9534 61.8989 21.2443 61.3346 21.3679C60.7962 21.4661 60.2325 21.5129 59.6436 21.5083L51.8475 21.4473L51.843 22.0234C51.8344 23.1243 52.237 24.0236 53.0509 24.7213C53.8906 25.3936 54.9761 25.735 56.3075 25.7454C57.1524 25.752 57.8575 25.6295 58.4227 25.3778C58.988 25.1262 59.4764 24.8868 59.8878 24.6596C60.2993 24.4323 60.6842 24.3201 61.0427 24.3229C61.4267 24.3259 61.7585 24.4565 62.0382 24.7148C62.3178 24.973 62.533 25.2819 62.6839 25.6416C62.8349 25.9756 62.9094 26.2707 62.9074 26.5267C62.904 26.9619 62.6186 27.4334 62.0514 27.9411C61.5099 28.4233 60.7257 28.8397 59.6988 29.1901C58.6977 29.5151 57.5058 29.6722 56.1232 29.6614ZM51.8703 18.5286L57.4774 18.5724C57.9126 18.5758 58.2076 18.5141 58.3622 18.3873C58.5426 18.2351 58.6344 17.9541 58.6376 17.5445C58.6422 16.9556 58.4799 16.4295 58.1507 15.966C57.847 15.5027 57.4402 15.1411 56.9301 14.8811C56.4457 14.6212 55.8962 14.4889 55.2817 14.4841C54.6929 14.4795 54.1414 14.6032 53.6273 14.8552C53.1135 15.0817 52.6883 15.424 52.3519 15.8823C52.0412 16.3151 51.8835 16.8388 51.8787 17.4532L51.8703 18.5286Z"
        fill="#0B378C"
      />
      <path
        d="M67.6608 29.406C66.9439 29.4004 66.3564 29.2293 65.8981 28.8929C65.4399 28.5565 65.2123 28.1834 65.2155 27.7738L65.3335 12.6808C65.3371 12.2199 65.5705 11.8505 66.0335 11.5724C66.4966 11.2944 67.0866 11.1582 67.8034 11.1638C68.4179 11.1686 68.9289 11.3134 69.3363 11.5983C69.7694 11.8833 69.9841 12.2562 69.9805 12.7171L69.9715 13.8692C70.4126 13.1301 71.0578 12.4695 71.9073 11.8872C72.757 11.2793 73.822 10.9804 75.1021 10.9904C76.2031 10.999 77.1732 11.3651 78.0125 12.0886C78.8518 12.8121 79.4718 13.7387 79.8727 14.8684C80.5486 13.5679 81.401 12.6144 82.4299 12.0079C83.4846 11.376 84.5625 11.0644 85.6634 11.073C86.8411 11.0822 87.9397 11.398 88.959 12.0205C90.0041 12.6176 90.8421 13.5075 91.473 14.6903C92.1296 15.8476 92.4515 17.2584 92.4384 18.9226L92.3676 27.9861C92.3644 28.3957 92.1183 28.765 91.6292 29.0941C91.1401 29.4231 90.55 29.5849 89.8587 29.5795C89.193 29.5743 88.6182 29.4034 88.1344 29.0668C87.6505 28.7301 87.4102 28.357 87.4134 27.9473L87.4843 18.8838C87.4903 18.1157 87.3416 17.4745 87.0384 16.96C86.7608 16.4457 86.3797 16.0587 85.8953 15.7989C85.411 15.5134 84.9129 15.3687 84.4008 15.3647C83.9144 15.3609 83.4396 15.498 82.9765 15.776C82.5137 16.0285 82.1266 16.4095 81.8154 16.9192C81.5041 17.4288 81.3456 18.0549 81.3398 18.7974L81.2684 27.9377C81.264 28.501 80.9919 28.9085 80.4523 29.1603C79.9384 29.3867 79.3742 29.4976 78.7597 29.4928C78.1965 29.4883 77.6469 29.3688 77.1111 29.1342C76.5754 28.874 76.3098 28.4622 76.3142 27.8989L76.3853 18.7971C76.3909 18.0802 76.2421 17.4645 75.9389 16.95C75.6613 16.4358 75.2803 16.0359 74.7961 15.7505C74.3374 15.4652 73.8392 15.3205 73.3016 15.3163C72.7895 15.3123 72.3021 15.4365 71.8392 15.689C71.3763 15.9414 70.9893 16.3224 70.6781 16.8321C70.3924 17.3419 70.2466 17.9809 70.2406 18.749L70.1697 27.8125C70.1665 28.2221 69.9204 28.5915 69.4313 28.9205C68.9423 29.2496 68.3521 29.4114 67.6608 29.406Z"
        fill="#0B378C"
      />
      <path
        d="M98.9861 29.9966C97.6291 29.9859 96.4546 29.5671 95.4625 28.74C94.4961 27.8875 94.0198 26.5907 94.0334 24.8497C94.0448 23.3903 94.425 22.2539 95.1739 21.4404C95.9484 20.6271 97.0026 20.0593 98.3365 19.7368C99.6961 19.4146 101.259 19.2604 103.026 19.2742L104.6 19.2865L104.604 18.7873C104.609 18.224 104.498 17.6726 104.272 17.1332C104.071 16.5939 103.729 16.1431 103.245 15.7809C102.787 15.4188 102.123 15.2344 101.252 15.2276C100.279 15.22 99.5233 15.3165 98.9841 15.5171C98.4706 15.6923 98.0722 15.8813 97.789 16.0839C97.5058 16.2865 97.1977 16.3865 96.8649 16.3839C96.3272 16.3797 95.9069 16.1076 95.6038 15.5675C95.3264 15.0276 95.1899 14.4761 95.1943 13.9128C95.1985 13.3751 95.5223 12.904 96.1655 12.4993C96.8346 12.0693 97.6437 11.7428 98.5928 11.5197C99.542 11.2967 100.465 11.1887 101.361 11.1957C103.358 11.2113 104.955 11.5823 106.153 12.3086C107.351 13.0349 108.214 13.9634 108.743 15.0941C109.297 16.2251 109.569 17.4434 109.559 18.7492L109.486 28.1199C109.482 28.5808 109.249 28.963 108.786 29.2666C108.322 29.5703 107.745 29.7194 107.054 29.714C106.414 29.709 105.877 29.5511 105.444 29.2405C105.037 28.9301 104.835 28.5444 104.839 28.0836L104.847 27.0082C104.15 27.7965 103.325 28.4942 102.373 29.1013C101.446 29.7085 100.317 30.007 98.9861 29.9966ZM101.012 26.3253C101.55 26.3295 102.089 26.1545 102.629 25.8003C103.17 25.446 103.621 24.9887 103.984 24.4282C104.373 23.868 104.569 23.2806 104.574 22.6661L104.58 21.8596L104.004 21.8551C103.159 21.8485 102.352 21.8934 101.584 21.9898C100.815 22.0862 100.186 22.3117 99.6963 22.6664C99.2328 22.9956 98.9983 23.5187 98.9927 24.2356C98.9871 24.9525 99.175 25.4788 99.5565 25.8147C99.9635 26.1507 100.449 26.3209 101.012 26.3253Z"
        fill="#0B378C"
      />
      <path
        d="M114.608 29.773C113.917 29.7676 113.342 29.5967 112.884 29.2603C112.425 28.9238 112.198 28.5508 112.201 28.1411L112.319 13.0481C112.323 12.5873 112.556 12.2178 113.019 11.9398C113.482 11.6618 114.059 11.5254 114.751 11.5309C115.391 11.5359 115.914 11.6808 116.322 11.9656C116.755 12.2507 116.97 12.6236 116.966 13.0845L116.954 14.659C117.24 14.098 117.615 13.576 118.08 13.0932C118.57 12.5849 119.124 12.1668 119.741 11.8387C120.358 11.5107 121.012 11.3494 121.704 11.3548L122.894 11.3641C123.381 11.3679 123.801 11.5888 124.156 12.0269C124.512 12.4393 124.687 12.94 124.682 13.5289C124.678 14.1433 124.494 14.6668 124.132 15.0992C123.771 15.5061 123.347 15.7076 122.86 15.7038L121.67 15.6945C120.953 15.6889 120.247 15.9266 119.552 16.4077C118.883 16.8633 118.327 17.5119 117.885 18.3534C117.443 19.1693 117.218 20.1661 117.209 21.3438L117.155 28.1799C117.152 28.5895 116.906 28.9589 116.417 29.2879C115.928 29.6169 115.325 29.7786 114.608 29.773Z"
        fill="#0B378C"
      />
      <path
        d="M128.088 29.8784C127.397 29.873 126.822 29.7021 126.364 29.3657C125.905 29.0292 125.678 28.6562 125.681 28.2465L125.882 2.51544C125.886 2.05459 126.119 1.68515 126.582 1.40711C127.045 1.12908 127.623 0.992772 128.314 0.998177C129.031 1.00378 129.631 1.1493 130.116 1.43474C130.6 1.72018 130.84 2.09332 130.837 2.55418L130.711 18.6457L138.022 11.9048C138.28 11.6508 138.601 11.5253 138.985 11.5283C139.37 11.5313 139.74 11.6622 140.096 11.9211C140.478 12.1545 140.783 12.4513 141.011 12.8116C141.264 13.172 141.389 13.5443 141.386 13.9283C141.385 14.0819 141.345 14.248 141.267 14.4267C141.215 14.5799 141.111 14.7199 140.957 14.8467L136.393 18.9206L142.014 27.03C142.191 27.313 142.278 27.5825 142.276 27.8386C142.273 28.2226 142.117 28.6055 141.806 28.9871C141.496 29.3688 141.122 29.6859 140.685 29.9385C140.248 30.1655 139.825 30.2774 139.415 30.2742C138.903 30.2702 138.495 30.0494 138.191 29.6118L133.104 21.9674L130.668 24.0992L130.635 28.2853C130.632 28.6949 130.386 29.0642 129.897 29.3933C129.408 29.7223 128.805 29.884 128.088 29.8784Z"
        fill="#0B378C"
      />
      <path
        d="M151.91 30.4103C150.092 30.3961 148.481 30.0635 147.078 29.4124C145.701 28.7359 144.62 27.8057 143.835 26.6217C143.051 25.4122 142.665 24.0265 142.677 22.4647L142.703 19.2003C142.714 17.8178 143.095 16.5533 143.846 15.407C144.624 14.2353 145.642 13.3087 146.902 12.6272C148.188 11.9203 149.612 11.573 151.174 11.5852C152.607 11.5964 153.911 11.9138 155.084 12.5375C156.282 13.1614 157.248 14.0139 157.983 15.095C158.717 16.1762 159.078 17.4336 159.067 18.8674C159.058 19.9683 158.873 20.7606 158.511 21.2443C158.148 21.7023 157.685 21.9932 157.121 22.1168C156.583 22.215 156.019 22.2618 155.43 22.2572L147.634 22.1962L147.629 22.7723C147.621 23.8732 148.023 24.7725 148.837 25.4702C149.677 26.1425 150.763 26.4839 152.094 26.4943C152.939 26.5009 153.644 26.3784 154.209 26.1267C154.774 25.8751 155.263 25.6357 155.674 25.4085C156.086 25.1812 156.471 25.069 156.829 25.0718C157.213 25.0748 157.545 25.2055 157.825 25.4637C158.104 25.7219 158.319 26.0309 158.47 26.3905C158.621 26.7245 158.696 27.0196 158.694 27.2756C158.69 27.7109 158.405 28.1823 157.838 28.69C157.296 29.1722 156.512 29.5886 155.485 29.939C154.484 30.264 153.292 30.4212 151.91 30.4103ZM147.657 19.2775L153.264 19.3213C153.699 19.3247 153.994 19.263 154.149 19.1362C154.329 18.984 154.421 18.7031 154.424 18.2934C154.429 17.7045 154.266 17.1784 153.937 16.7149C153.633 16.2517 153.227 15.89 152.717 15.63C152.232 15.3701 151.683 15.2378 151.068 15.233C150.479 15.2284 149.928 15.3521 149.414 15.6042C148.9 15.8306 148.475 16.1729 148.138 16.6312C147.828 17.064 147.67 17.5877 147.665 18.2022L147.657 19.2775Z"
        fill="#0B378C"
      />
      <path
        d="M163.409 30.1546C162.718 30.1492 162.143 29.9783 161.685 29.6418C161.226 29.3054 160.999 28.9323 161.002 28.5227L161.12 13.4297C161.124 12.9688 161.357 12.5994 161.82 12.3213C162.283 12.0433 162.86 11.907 163.551 11.9124C164.192 11.9174 164.715 12.0623 165.123 12.3472C165.556 12.6322 165.771 13.0052 165.767 13.466L165.755 15.0406C166.041 14.4795 166.416 13.9576 166.881 13.4747C167.371 12.9665 167.925 12.5483 168.542 12.2203C169.159 11.8922 169.813 11.7309 170.505 11.7363L171.695 11.7456C172.182 11.7494 172.602 11.9704 172.957 12.4084C173.313 12.8209 173.488 13.3215 173.483 13.9104C173.478 14.5249 173.295 15.0483 172.933 15.4808C172.572 15.8876 172.148 16.0892 171.661 16.0854L170.471 16.076C169.754 16.0704 169.048 16.3082 168.353 16.7892C167.683 17.2449 167.128 17.8934 166.686 18.7349C166.244 19.5508 166.019 20.5477 166.01 21.7254L165.956 28.5614C165.953 28.9711 165.707 29.3404 165.218 29.6694C164.729 29.9985 164.126 30.1602 163.409 30.1546Z"
        fill="#0B378C"
      />
    </svg>
  );
};

export default BluemarkerFullLogo;
