import { useMutation } from '@tanstack/react-query';

import API from '../api';

const signInWithMicrosoft = async (payload: { token: string; context: string }) =>
  await API.post('/authentication/microsoft', payload);

const useSignInWithMicrosoft = () =>
  useMutation({
    mutationFn: signInWithMicrosoft,
  });

export default useSignInWithMicrosoft;
