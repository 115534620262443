'use client';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { extendI18nTranslation, type I18nTranslation } from './extend-i18n';

const useExtendI18nTranslation = (...translations: I18nTranslation[]) => {
  const [extended, setExtended] = useState(false);

  useEffect(() => {
    !extended && extendI18nTranslation(...translations);
    setExtended(true);
  }, [extended]);

  return useTranslation();
};

export default useExtendI18nTranslation;
