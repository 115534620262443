import type { Theme } from './type';

export const DEFAULT_THEME: Theme = {
  neutral: {
    '0': '#ffffff',
    '1': '#f0f2f5',
    '2': '#e3e6ec',
    '3': '#d1d5e0',
    '4': '#b7bdcd',
    '5': '#8c94a9',
    '6': '#3b4154',
    '7': '#1c202d',
    '8': '#0f111a',
  },
  primary: {
    '0': '#cbe1f9',
    '1': '#99c1f3',
    '2': '#6294dc',
    '3': '#3969ba',
    '4': '#0b378c',
    '5': '#082a78',
    '6': '#051f64',
    '7': '#031551',
    '8': '#020f43',
  },
  success: {
    '0': '#e1f8cd',
    '1': '#bef19f',
    '2': '#8ad769',
    '3': '#59b03f',
    '4': '#227c12',
    '5': '#146a0d',
    '6': '#146a0d',
    '7': '#05470b',
    '8': '#033b0c',
  },
  info: {
    '0': '#cdfafd',
    '1': '#9cf0fb',
    '2': '#6adbf3',
    '3': '#44c0e8',
    '4': '#0d9ada',
    '5': '#0978bb',
    '6': '#065a9c',
    '7': '#04407e',
    '8': '#022d68',
  },
  warning: {
    '0': '#fdf8cf',
    '1': '#fcf0a1',
    '2': '#f7e271',
    '3': '#f0d34d',
    '4': '#e7be16',
    '5': '#c69f10',
    '6': '#a6810b',
    '7': '#856507',
    '8': '#6e5104',
  },
  danger: {
    '0': '#fde5cc',
    '1': '#fcc69a',
    '2': '#f69d66',
    '3': '#ec7540',
    '4': '#e13a07',
    '5': '#c12305',
    '6': '#a21103',
    '7': '#820402',
    '8': '#6c0107',
  },
};
