import type { PropsWithChildren } from 'react';

import AnimatedBackground from './animated-background';
import LanguageSwitcher from './language-switcher';

export type SideWallpaperLayoutProps = PropsWithChildren<{
  logoUrl?: string;
  wallpaperUrl?: string;
  bluemarkerPlatform?: string;
}>;

const SideWallpaperLayout = ({ children, logoUrl, wallpaperUrl, bluemarkerPlatform }: SideWallpaperLayoutProps) => {
  return (
    <main className="w-screen h-screen flex justify-center items-center bg-neutral-0">
      <div className="hidden lg:block lg:fit p-8 h-full">
        {wallpaperUrl ? (
          <img
            alt="wallpaper"
            className="animate-in fade-in-0 duration-1000 h-full object-cover rounded-xl shadow-lg bg-primary-4 max-w-[60vw]"
            src={wallpaperUrl}
          />
        ) : (
          <AnimatedBackground
            className="rounded-xl shadow-xl w-[calc(60vw)]"
            logoUrl={logoUrl}
            bluemarkerPlatform={bluemarkerPlatform}
          />
        )}
      </div>
      <div className="h-full w-full lg:flex-1 flex items-center justify-center lg:p-12 relative">
        <LanguageSwitcher className="absolute top-8 right-8" />
        <div className="max-w-lg w-full">{children}</div>
      </div>
    </main>
  );
};

export default SideWallpaperLayout;
