import { useQuery } from '@tanstack/react-query';

import API from '../api';

export type UserProfile = {
  id: string;
  name: string;
  email: string;
  isAdmin: boolean;
  avatarUrl?: string;
};

export const AUTHENTICATION_USER_PROFILE_PATH = '/authentication/user-profile';

const queryUserProfile = async () => {
  const { data } = await API.get<UserProfile>(AUTHENTICATION_USER_PROFILE_PATH);
  return data;
};

const useQueryUserProfile = () => {
  return useQuery({
    queryKey: [AUTHENTICATION_USER_PROFILE_PATH],
    queryFn: queryUserProfile,
  });
};

export default useQueryUserProfile;
