import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { LoadingIndicator } from '@bluemarker/ui';
import { DotIcon } from '@bluemarker/ui/icons';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const TRANSLATION: I18nTranslation = {
  en: {
    redirecting: 'Redirecting',
  },
  vi: {
    redirecting: 'Đang điều hướng',
  },
};

const SignInRedirect = () => {
  const { t } = useExtendI18nTranslation(TRANSLATION);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('error')) {
      searchParams.delete('error');
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <div className="flex flex-col items-center gap-4 animate-pulse cursor-wait py-10 animate-in fade-in-0">
      <LoadingIndicator />
      <div className="flex">
        <p className="text-neutral-5">{t('redirecting')}</p>{' '}
        <span className="flex -ml-1 mt-1.5">
          <DotIcon className="text-neutral-5 animate-bounce [animation-delay:-0.3s]" />
          <DotIcon className="-ml-4 text-neutral-5 animate-bounce [animation-delay:-0.15s]" />
          <DotIcon className="-ml-4 text-neutral-5 animate-bounce" />
        </span>
      </div>
    </div>
  );
};

export default SignInRedirect;
