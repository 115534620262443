import { useMsal } from '@azure/msal-react';
import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { Button, LoadingIndicator } from '@bluemarker/ui';
import { MicrosoftIcon } from '@bluemarker/ui/icons';
import { useEffect } from 'react';

import useSignInWithMicrosoft from '@/apis/authentication/use-sign-in-with-microsoft';

const TRANSLATION: I18nTranslation = {
  en: {
    signInWithMicrosoft: 'Sign in with Microsoft',
  },
  vi: {
    signInWithMicrosoft: 'Đăng nhập với Microsoft',
  },
};

type SignInWithMicrosoftButtonProps = {
  context: string;
  onSuccess: () => void;
  onError: () => void;
};

const SignInWithMicrosoftButton = ({ onSuccess, onError, context }: SignInWithMicrosoftButtonProps) => {
  const { t } = useExtendI18nTranslation(TRANSLATION);
  const { mutate: signInWithMicrosoft, isPending, isSuccess, isError } = useSignInWithMicrosoft();
  const { instance } = useMsal();

  const handleSignIn = async () => {
    const data = await instance.loginPopup({
      scopes: ['openid', 'profile', 'email', 'user.read'],
    });
    signInWithMicrosoft({ token: data.accessToken, context });
  };

  useEffect(() => {
    isSuccess && onSuccess();
  }, [isSuccess]);

  useEffect(() => {
    isError && onError();
  }, [isError]);

  return (
    <Button variant="outline" className="text-base py-2 h-fit" disabled={isPending} onClick={void handleSignIn}>
      {isPending ? <LoadingIndicator className="w-6 h-6 mr-2" /> : <MicrosoftIcon className="w-6 h-6 mr-2" />}
      {t('signInWithMicrosoft')}
    </Button>
  );
};

export default SignInWithMicrosoftButton;
