import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { cn } from '@bluemarker/ui';

const TRANSLATION: I18nTranslation = {
  en: {
    welcome: 'Welcome back!',
    signInInstruction: 'Choose one of the methods below to sign in',
  },
  vi: {
    welcome: 'Chào mừng trở lại!',
    signInInstruction: 'Hãy chọn một phương thức đăng nhập bên dưới',
  },
};

type SignInInstructionProps = {
  className?: string;
};

const SignInInstruction = ({ className }: SignInInstructionProps) => {
  const { t } = useExtendI18nTranslation(TRANSLATION);

  return (
    <div className={cn('pb-10 text-center', className)}>
      <h1 className="text-xl">{t('welcome')}</h1>
      <p className="text-neutral-5 text-sm">{t('signInInstruction')}</p>
    </div>
  );
};

export default SignInInstruction;
