import type { ComponentProps } from 'react';

const MicrosoftIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.625 11.375H22V2.625C22 2.28 21.72 2 21.375 2H12.625V11.375Z" fill="#4CAF50"></path>
      <path d="M11.375 11.375V2H2.625C2.28 2 2 2.28 2 2.625V11.375H11.375Z" fill="#F44336"></path>
      <path d="M11.375 12.625H2V21.375C2 21.72 2.28 22 2.625 22H11.375V12.625Z" fill="#2196F3"></path>
      <path d="M12.625 12.625V22H21.375C21.72 22 22 21.72 22 21.375V12.625H12.625Z" fill="#FFC107"></path>
    </svg>
  );
};

export default MicrosoftIcon;
