import type { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

export type WhiteSvgImageProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const WhiteSvgImage = ({ ...props }: WhiteSvgImageProps) => {
  return (
    <img
      {...props}
      alt={props.alt}
      style={{
        ...(props.style ?? {}),
        filter: 'grayscale(100%) brightness(5)',
        WebkitFilter: 'grayscale(100%) brightness(5)',
      }}
    />
  );
};

export default WhiteSvgImage;
