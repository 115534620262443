'use client';

import { createContext as reactCreateContext, useContext } from 'react';

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export const createContext = <A>(): readonly [() => A, React.Provider<A | undefined>, React.Context<A | undefined>] => {
  const ctx = reactCreateContext<A | undefined>(undefined);

  // eslint-disable-next-line sonarjs/function-return-type
  const useCtx = () => {
    const c = useContext(ctx);
    if (c === undefined) {
      throw new Error('useCtx must be inside a Provider with a value');
    }
    return c;
  };

  return [useCtx, ctx.Provider, ctx];
};
