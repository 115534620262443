import { useQuery } from '@tanstack/react-query';

import API from '../api';
import type { OrganizationUserRole } from './enum/organization-user-role.enum';

export type OrganizationUserProfile = {
  id: string;
  email: string;
  name: string;
  organizationRole: OrganizationUserRole;
  avatarUrl?: string;
};

const queryOrganizationUserProfile = async (subdomain?: string) => {
  if (!subdomain) {
    return null;
  }

  const { data } = await API.get<OrganizationUserProfile>(`/authentication/${subdomain}/user-profile`);
  return data;
};

const useQueryOrganizationUserProfile = (subdomain?: string) => {
  return useQuery({
    queryKey: ['/authentication/organization/profile', subdomain],
    queryFn: () => queryOrganizationUserProfile(subdomain),
  });
};

export default useQueryOrganizationUserProfile;
