import { useExtendI18nTranslation } from '@bluemarker/core';
import { Alert, AlertDescription, AlertTitle } from '@bluemarker/ui';
import { InfoIcon } from '@bluemarker/ui/icons';
import { useSearchParams } from 'react-router-dom';

const SESSION_EXPIRED_ERROR = 'session-expired';

const TRANSLATION = {
  en: {
    signInExpirationTitle: 'Your session has expired',
    signInExpirationDescription: 'Please sign in again',
  },
  vi: {
    signInExpirationTitle: 'Phiên của bạn đã hết hạn',
    signInExpirationDescription: 'Xin vui lòng đăng nhập lại',
  },
};

const SignInExpirationAlert = () => {
  const [searchParams] = useSearchParams();
  const { t } = useExtendI18nTranslation(TRANSLATION);

  const errorSessionExpired = searchParams.get('error') === SESSION_EXPIRED_ERROR;

  return errorSessionExpired ? (
    <Alert className="animate-in animate-accordion-down">
      <InfoIcon className="h-5 w-5" />
      <AlertTitle>{t('signInExpirationTitle')}</AlertTitle>
      <AlertDescription>{t('signInExpirationDescription')}</AlertDescription>
    </Alert>
  ) : null;
};

export default SignInExpirationAlert;
