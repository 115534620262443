import { LoadingScreen } from '@bluemarker/ui';
import { useEffect } from 'react';

const ClosePage = () => {
  useEffect(() => {
    window.close();
  }, []);

  return <LoadingScreen />;
};

export default ClosePage;
