import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { Button, cn } from '@bluemarker/ui';
import { Link } from 'react-router-dom';

const TRANSLATION: I18nTranslation = {
  en: {
    acknowledgement: 'By signing in, you are agreeing to our ',
    privacyPolicy: 'Privacy Policy',
    our: '',
  },
  vi: {
    acknowledgement: 'Bằng việc đăng nhập tài khoản, bạn đã đồng ý với ',
    privacyPolicy: 'Chính sách bảo mật',
    our: 'của chúng tôi',
  },
};

type SignInPrivacyPolicyProps = {
  className?: string;
};

const SignInPrivacyPolicy = ({ className }: SignInPrivacyPolicyProps) => {
  const { t } = useExtendI18nTranslation(TRANSLATION);

  return (
    <p className={cn('text-neutral-5 text-sm text-center', className)}>
      {t('acknowledgement')}
      <Link to="https://cvhtechnology.com/privacy-policy" target="__blank" rel="noreferrer" className="font-bold">
        <Button className="px-0" variant="link">
          {t('privacyPolicy')}
        </Button>
      </Link>{' '}
      {t('our') !== '' && t('our')}.
    </p>
  );
};

export default SignInPrivacyPolicy;
