import type { ComponentProps } from 'react';

const VietnamFlagIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 85.333 512 341.333" {...props}>
      <polygon fill="#D80027" points="196.641,85.337 0,85.337 0,426.663 196.641,426.663 512,426.663 512,85.337 " />
      <polygon
        fill="#FFDA44"
        points="256,157.279 278.663,227.026 352,227.026 292.668,270.132 315.332,339.881 256,296.774   196.668,339.881 219.332,270.132 160,227.026 233.337,227.026 "
      />
    </svg>
  );
};

export default VietnamFlagIcon;
