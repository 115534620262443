import type { Theme } from '@bluemarker/ui';
import { useQuery } from '@tanstack/react-query';

import API from '../api';

export type OrganizationProfile = {
  id: string;
  name: string;
  subdomain: string;
  theme?: Theme;
  faviconUrl: string;
  logoUrl: string;
  websiteUrl: string;
  wallpaperUrl: string;
  isActivated: boolean;
};

const queryOrganizationProfile = async (subdomain?: string) => {
  if (!subdomain) return null;

  const { data } = await API.get<OrganizationProfile>(`/organization/${subdomain}/profile`);
  return data;
};

const useQueryOrganizationProfile = (subdomain?: string) => {
  return useQuery({
    queryKey: ['/organization/profile', subdomain],
    queryFn: () => queryOrganizationProfile(subdomain),
  });
};

export default useQueryOrganizationProfile;
