import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { Button, LoadingIndicator } from '@bluemarker/ui';
import { GoogleIcon } from '@bluemarker/ui/icons';
import { type TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useEffect } from 'react';

import useSignInWithGoogle from '@/apis/authentication/use-sign-in-with-google';

const TRANSLATION: I18nTranslation = {
  en: {
    signInWithGoogle: 'Sign in with Google',
  },
  vi: {
    signInWithGoogle: 'Đăng nhập với Google',
  },
};

type SignInWithGoogleButtonProps = {
  context: string;
  onSuccess: () => void;
  onError: () => void;
};

const SignInWithGoogleButton = ({ onSuccess, onError, context }: SignInWithGoogleButtonProps) => {
  const { t } = useExtendI18nTranslation(TRANSLATION);
  const { mutate: signInWithGoogle, isPending, isSuccess, isError } = useSignInWithGoogle();

  const handleSignIn = useGoogleLogin({
    onSuccess: (tokenResponse: TokenResponse) =>
      signInWithGoogle({
        token: tokenResponse.access_token,
        context,
      }),
  });

  useEffect(() => {
    isSuccess && onSuccess();
  }, [isSuccess]);

  useEffect(() => {
    isError && onError();
  }, [isError]);

  return (
    <Button variant="outline" className="text-base py-2 h-fit" disabled={isPending} onClick={() => handleSignIn()}>
      {isPending ? <LoadingIndicator className="w-6 h-6 mr-2" /> : <GoogleIcon className="w-6 h-6 mr-2" />}
      {t('signInWithGoogle')}
    </Button>
  );
};

export default SignInWithGoogleButton;
