import { useMutation } from '@tanstack/react-query';

import API from '../api';

const signInWithGoogle = async (payload: { token: string; context: string }) =>
  await API.post('/authentication/google', payload);

const useSignInWithGoogle = () =>
  useMutation({
    mutationFn: signInWithGoogle,
  });

export default useSignInWithGoogle;
